'use client'

import { useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { SwitchTransition, CSSTransition, TransitionGroup } from 'react-transition-group'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Title from '@/components/Elements/Title/Title'
import Icon from '@/components/Elements/Icon/Icon'

import styles from './FaqSection.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const FaqSection = ({ attributes, index }) => {
    const { faq, title, subtitle, description, displayNav = true } = attributes
    const sectionRef = useRef(null)
    const [activeTab, setActiveTab] = useState('all')

    let keys = [...new Set(faq.map((faq) => faq.key))]

    return (
        <SectionWrap className={classes('section-wrap')} ref={sectionRef}>
            {attributes.title && (
                <Title
                    title={title}
                    description={description}
                    subtitle={subtitle}
                    index={index}
                    gradient={index == 0}
                    className={index == 0 ? classes('section-title') : null}
                    smallGradient={true}
                />
            )}
            <div className={classes('faq-section')}>
                {displayNav && <TabNav keys={keys} activeTab={activeTab} setActiveTab={setActiveTab} />}
                <Questions faq={faq} activeTab={activeTab} />
            </div>
        </SectionWrap>
    )
}

export default FaqSection

const TabNav = ({ keys, activeTab, setActiveTab }) => {
    const tabsRef = useRef([])

    return (
        <div className={classes('tab-nav')}>
            <div
                key={'all-faqs'}
                className={classes('key-wrap', { active: 'all' === activeTab })}
                onClick={() => setActiveTab('all')}
            >
                <span className={classes('key')}>All</span>
            </div>
            {keys &&
                keys.map((key, i) => {
                    i += 1
                    if (!key) return null

                    return (
                        <div
                            key={i}
                            className={classes('key-wrap', { active: key === activeTab })}
                            onClick={() => {
                                setActiveTab(key)
                            }}
                            ref={(el) => (tabsRef.current[i] = el)}
                        >
                            <span className={classes('key')}>{key}</span>
                        </div>
                    )
                })}
        </div>
    )
}

const Questions = ({ faq, activeTab }) => {
    const [openSubSection, setOpenSubSection] = useState(false)

    return (
        <TransitionGroup className={classes('transition-wrap')}>
            <SwitchTransition mode={'out-in'}>
                <CSSTransition
                    key={activeTab}
                    unmountOnExit
                    mountOnEnter
                    timeout={200}
                    classNames={{
                        enter: classes('enter'),
                        enterActive: classes('enter-active'),
                        exit: classes('exit'),
                        exitActive: classes('exit-active'),
                    }}
                >
                    <div className={classes('questions')}>
                        {faq.map((faq, i) => {
                            return (
                                <Question
                                    key={i}
                                    faq={faq}
                                    i={i}
                                    activeTab={activeTab}
                                    setOpenSubSection={setOpenSubSection}
                                    openSubSection={openSubSection}
                                />
                            )
                        })}
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </TransitionGroup>
    )
}

const Question = ({ faq, i, activeTab, setOpenSubSection, openSubSection }) => {
    return (
        <div
            key={i}
            className={classes(
                'question-wrap',
                { active: faq.key == activeTab || 'all' == activeTab },
                { open: openSubSection == faq.question }
            )}
            onClick={() => setOpenSubSection(openSubSection == faq.question ? null : faq.question)}
        >
            <div className={classes('question-row')}>
                <div className={classes('icon-wrap')}>
                    <Icon icon={openSubSection == faq.question ? 'minus' : 'plus'} className={classes('icon')} />
                </div>
                <div className={classes('qa')}>
                    <h3 className={classes('question')}>{faq.question}</h3>
                    <AnimateHeight
                        height={openSubSection == faq.question ? 'auto' : 0}
                        animateOpacity={true}
                        applyInlineTransitions={true}
                        duration={200}
                    >
                        <p className={classes('answer')}>{faq.answer}</p>
                    </AnimateHeight>
                </div>
            </div>
            <div className={classes('hr')} />
        </div>
    )
}
