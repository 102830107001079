'use client'

import styles from './Calculator.module.scss'
import classNames from 'classnames/bind'

import { CSSTransition } from 'react-transition-group'

import { useState, useRef } from 'react'
import { getValue } from '@/utils/index.js'

import sections from './config.json'

import { CalculatorProvider, useCustomContext } from './CalculatorContext'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Input from '@/components/Elements/Input/Input'
import Dropdown from '@/components/Elements/Dropdown/Dropdown'
import MultipleDropdown from '@/components/Sections/Calculator/MultipleDropdown/MultipleDropdown'
import Button from '@/components/Links/Button/Button'
import Breakdown from './Results/Breakdown'
import Summary from './Results/Summary'

import HelpIcon from '@/icons/help-circle-full.svg'
import Alert from '@/icons/alert-circle.svg'
import Reset from '@/icons/rotate-ccw.svg'
import Close from '@/icons/x.svg'
import Tick from '@/icons/check.svg'
import Inbox from '@/icons/inbox.svg'
import Title from '@/components/Elements/Title/Title'

const classes = classNames.bind(styles)

const Calculator = ({ attributes }) => {
    return (
        <SectionWrap className={classes('wrap')}>
            {attributes.title && (
                <Title
                    title={attributes.title}
                    description={attributes?.description}
                    index={0}
                    gradient={true}
                    className={classes('section-title')}
                />
            )}
            <CalculatorProvider type={attributes.type}>
                <CalculatorContent />
            </CalculatorProvider>
        </SectionWrap>
    )
}

const CalculatorContent = () => {
    const { state } = useCustomContext()

    return (
        <div className={classes('container')}>
            <div className={classes('header-wrap')}>
                <div className={classes('header-wrap')}>
                    <h6 className={classes('title')}>{state.config.title}</h6>
                    <h2 className={classes('subtitle')}>{state.config.description}</h2>
                    {state.config.custom && <MultiDropdown />}
                </div>
            </div>
            <div className={classes('calculator-wrap')}>
                <div className={classes('calculator-inputs')}>
                    <EnabledBoxes />
                    <CalculatorBottom />
                    <Disclaimer />
                </div>
                <SummarySection />
            </div>
        </div>
    )
}

const EnabledBoxes = () => {
    const { state } = useCustomContext()

    // Iterate through the enabled boxes and populate.
    return state.enabledBoxes.map((sectionKey, i) => {
        const section = sections[sectionKey]

        return (
            <div className={classes('input-section')} key={`section-${i}`}>
                {section.title && (
                    <div className={classes('section-splitter')}>
                        <span className={classes('input-title')}>{section.title}</span>
                        <div className={classes('hr')} />
                    </div>
                )}
                <div className={classes('inputs-wrap')}>
                    {section.questions.map((input, i) => {
                        return <InputContainer key={`${input.section}-${input.key}-${i}`} inputData={input} />
                    })}
                </div>
            </div>
        )
    })
}

const InputContainer = ({ inputData }) => {
    const { type, label, unit, help = false, helpText = null, helpTitle = null } = inputData
    const [showHelp, setShowHelp] = useState(false)
    const helpRef = useRef(null)
    console.log(showHelp)
    // Render input based on which type of input is needed.
    return (
        <div className={classes('input-container')}>
            <div className={classes('label-row')}>
                <label className={classes('label')} htmlFor={type}>
                    {label}
                </label>
                {help && helpText && helpTitle && (
                    <div className={classes('help-wrap')}>
                        <HelpIcon onMouseEnter={() => setShowHelp(true)} onMouseLeave={() => setShowHelp(false)} />
                        <CSSTransition
                            in={showHelp}
                            timeout={300}
                            classNames={{
                                enter: classes('enter'),
                                enterActive: classes('enter-active'),
                                exit: classes('exit'),
                                exitActive: classes('exit-active'),
                            }}
                            nodeRef={helpRef}
                            unmountOnExit
                            mountOnEnter
                        >
                            <div className={classes('text-wrap')}>
                                <span className={classes('title')}>{helpTitle}</span>
                                <span className={classes('text')}>{helpText}</span>
                            </div>
                        </CSSTransition>
                    </div>
                )}
            </div>
            <div className={classes('input-wrapper', { 'currency-input-container': unit === '£' })}>
                {/* Add the symbol if the field needs a symbol. */}
                {unit && <span>{unit}</span>}
                <InputType config={inputData} />
            </div>
        </div>
    )
}

const InputType = ({ config }) => {
    const { state, dispatch } = useCustomContext()
    const { key, position } = config

    const answer = getValue(state, position ? `answer.${position}.${key}` : `answer.${key}`, '')
    const onChange = ({ target }) => {
        if (position) {
            dispatch({ type: 'SET_ANSWER', payload: { ...config, value: target.value } })
        } else {
            dispatch({ type: 'SET_ANSWER', payload: { ...config, key, value: target.value } })
        }
    }

    // Render input based on which type of input is needed.
    switch (config.type) {
        case 'radio':
            return <RadioInput choices={config.choices} onChange={onChange} value={answer} />
        case 'dropdown':
            return (
                <Dropdown
                    id={key}
                    listName={config.label}
                    required={true}
                    choices={config.choices}
                    onChange={onChange}
                    answer={answer}
                />
            )
        default:
            return (
                <Input
                    className={classes('input')}
                    type={config.type == 'string' ? 'text' : 'number'}
                    id={key}
                    onChange={onChange}
                    value={answer}
                />
            )
    }
}

const RadioInput = ({ choices, value, onChange }) => {
    return (
        <div className={classes('radio-group')}>
            {choices.map(({ key, name }, i) => {
                return (
                    <div
                        key={`radio-${i}`}
                        onClick={() => {
                            onChange({ target: { value: key } })
                        }}
                    >
                        <input
                            id={key}
                            value={key}
                            type={'radio'}
                            name={name}
                            className={classes('radio')}
                            checked={value == key}
                        />
                        <label htmlFor={name} className={classes('radio-container')}>
                            <div className={classes('svg-container')}>
                                <Tick />
                            </div>
                            {name}
                        </label>
                    </div>
                )
            })}
        </div>
    )
}

const MultiDropdown = () => {
    // Get the sections that can be enabled.
    const sectionTypes = Object.entries(sections)
        .filter(([_, { type }]) => type)
        .map(([key, { title, type }]) => ({ key, name: title, type }))

    // Get the sections per each dropdown.
    const dropdowns = [
        { title: 'Income', options: sectionTypes.filter(({ type }) => type == 'income') },
        { title: 'Credits', options: sectionTypes.filter(({ type }) => type == 'credits') },
        { title: 'Gains', options: sectionTypes.filter(({ type }) => type == 'capitalGains') },
    ]

    // Iterate through dropdown types and populate.
    return (
        <div className={classes('dropdown-section')}>
            {dropdowns.map(({ title, options }, i) => (
                <div className={classes('dropdown-wrap')} key={i}>
                    <MultipleDropdown options={options} title={title} />
                </div>
            ))}
        </div>
    )
}

const CalculatorBottom = () => {
    const { state, dispatch } = useCustomContext()

    const taxYearConfig = {
        type: 'dropdown',
        key: 'tax-year',
        label: 'Tax Year',
        choices: [
            { name: '2020/2021', key: '__2020-21' },
            { name: '2021/2022', key: '__2021-22' },
            { name: '2022/2023', key: '__2022-23' },
            { name: '2023/2024', key: '__2023-24' },
            // { name: '2024/2025', key: '__2024-25' },
        ],
        help: true,
    }

    const fetchCalc = async () => {
        // Get the enabled boxes and create a payload.
        try {
            const fetchAPI = (await import('@/utils/strapiApi.js')).default
            const response = await fetchAPI('/calculator', {}, { method: 'POST', body: JSON.stringify(state.answer) })

            dispatch({ type: 'SET_RESULTS', payload: response })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={classes('button-wrap')}>
            <InputContainer inputData={taxYearConfig} />
            <Button
                extraClasses={classes('button', 'primary')}
                text={'Calculate'}
                onClick={(e) => {
                    e.preventDefault()
                    fetchCalc()
                }}
            />
        </div>
    )
}

const Disclaimer = () => {
    const [disclaimer, setDisclaimer] = useState(true)

    // Show disclaimer box and allow user to close.
    if (disclaimer) {
        return (
            <div className={classes('alert-box')}>
                <div className={classes('svg-wrap')}>
                    <Alert />
                </div>
                <p className={classes('alert-text')}>
                    Your results will not be saved. This calculator is a simplified version of our tax return software,
                    where we calculate and file your tax return with all the relevant information. This calculator
                    should be used for estimations, capital gains is complicated with potential reliefs available,
                    please get in touch if you have any queries.
                </p>
                <div className={classes('svg-wrap')} onClick={() => setDisclaimer(false)}>
                    <Close />
                </div>
            </div>
        )
    }
    return null
}

const SummarySection = () => {
    const { state, dispatch } = useCustomContext()

    let summaryConfig = {
        title: 'Tax Breakdown',
        keys: state.summaryBoxes,
    }

    // Render the summary section.
    return (
        <div className={classes('summary-wrap')}>
            <div className={classes('header-bubble')}>
                <span className={classes('header')}>Estimations</span>
            </div>
            {/* Iterate through the results that need to be shown and render an output */}
            {/* If there are now results then show a message in the summary box */}
            {Object.values(state.results).length == 0 ? (
                <>
                    <span className={classes('breakdown-title')}>Tax breakdown</span>
                    <div className={classes('border-summary')}>
                        <div className={classes('empty-summary')}>
                            <Inbox />
                            <span className={classes('empty-text')}>Your tax breakdown will appear here</span>
                        </div>
                    </div>
                </>
            ) : (
                <div className={classes('summary-container')}>
                    {state.config.summary_type === 'summary' ? (
                        <Summary results={state.results} calculatorType={state.calculatorKey} />
                    ) : (
                        <Breakdown results={state.results} summaryConfig={summaryConfig} />
                    )}
                </div>
            )}
            <Button
                extraClasses={classes('button', 'reset')}
                text={'Reset'}
                icon={<Reset />}
                iconSide={'left'}
                onClick={() => {
                    dispatch({ type: 'RESET_ANSWERS' })
                }}
            />
        </div>
    )
}

export default Calculator
