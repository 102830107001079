'use client'

import { useRef, useState } from 'react'

import styles from './MobilePricing.module.scss'
import classNames from 'classnames/bind'

import Modal from '@/components/Elements/Modal/Modal'
import Button from '@/components/Links/Button/Button'
import Icon from '@/components/Elements/Icon/Icon'
import Check from '@/icons/check-icon.svg'

const classes = classNames.bind(styles)

const MobilePricing = ({ config }) => {
    const { packages, features, sub_sections = [] } = config
    const [open, setOpen] = useState(false)

    return (
        <div className={classes('mobile-pricing')}>
            {packages.map((plan, i) => {
                return (
                    <div key={`config-${i}`} className={classes('pricing-wrap')}>
                        <div className={classes('pricing-wrap')}>
                            <PackageTitle
                                primaryText={plan.primaryText}
                                secondaryText={plan.secondaryText}
                                subtitle={plan.subtitle}
                                features={packages[i]?.features}
                            />
                        </div>
                        <div className={classes('hr')} />
                        <PackageCards cards={plan.cards} setOpen={setOpen} />
                        <PackageBoltOns boltOns={plan.boltOns} setOpen={setOpen} />
                        <Button
                            text={'Get started'}
                            type={'primary'}
                            extraClasses={['square', classes('button')]}
                            url={'https://intro.taxd.co.uk/'}
                            newTab={true}
                        />
                        {plan.boltOns.help && (
                            <Modal open={open} setOpen={setOpen} className={classes('modal')}>
                                {plan.boltOns.help.map((help, i) => {
                                    return (
                                        <div key={`help-${i}`}>
                                            {i !== 0 && <div className={classes('seperator')} />}
                                            <div key={`help-${i}`} className={classes('modal-help')}>
                                                <div className={classes('modal-title-wrap')}>
                                                    <span className={classes('modal-title', { small: i !== 0 })}>
                                                        {help.title}
                                                    </span>
                                                </div>

                                                {help.content && (
                                                    <p className={classes('modal-content')}>{help.content}</p>
                                                )}
                                                {help.list && (
                                                    <ul>
                                                        {help.list.map((item, i) => {
                                                            return (
                                                                <li key={`item-${i}`}>
                                                                    <Icon
                                                                        icon="check-icon"
                                                                        className={classes('list-icon')}
                                                                    />

                                                                    {item}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Modal>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default MobilePricing

const PackageTitle = ({ primaryText, secondaryText, subtitle, features }) => {
    return (
        <>
            <div className={classes('pricing-title-wrap')}>
                <span className={classes('subtitle')}>{subtitle}</span>
                <h2 className={classes('title')}>
                    {primaryText}
                    {secondaryText && <span className={classes('per-month')}>{secondaryText}</span>}
                </h2>
            </div>
            <div className={classes('hr')} />
            <div className={classes('features')}>
                <span className={classes('feature-title')}>Includes</span>
                {features.map((feature, i) => {
                    return (
                        <div key={`label-${i}`} className={classes('feature')}>
                            <div className={classes('icon-wrap')}>
                                <Check />
                            </div>
                            <span className={classes('label')}>{feature}</span>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

const PackageCards = ({ cards, setOpen }) => {
    if (!cards) return null

    return (
        <>
            <div className={classes('cards-wrap')}>
                {cards.map((card, i) => {
                    return (
                        <div key={`card-${i}`} className={classes('card')} onClick={() => setOpen(true)}>
                            <div className={classes('card-top-wrap')}>
                                <div className={classes('card-title-wrap')}>
                                    <span className={classes('card-title')}>{card.title}</span>
                                    <p className={classes('card-price')}>
                                        {card.price}
                                        <span>/return</span>
                                    </p>
                                </div>
                                <div className={classes('card-feature-wrap')}>
                                    {card.features.map((feature, i) => {
                                        return (
                                            <div key={`feature-${i}`} className={classes('card-feature')}>
                                                <span className={classes('card-label')}>{feature.label}</span>
                                                <span className={classes('card-label')}>{feature.value}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <p className={classes('card-info')}>{card.info}</p>
                        </div>
                    )
                })}
            </div>
            <div className={classes('hr')} />
        </>
    )
}

const PackageBoltOns = ({ boltOns, setOpen }) => {
    return (
        <div className={classes('bolt-wrap')}>
            <div className={classes('bolt-title-wrap')} onClick={() => setOpen(true)}>
                <span className={classes('bolt-title')}>{boltOns.title}</span>
                <Icon icon={'help-circle'} />
            </div>
            <div className={classes('bolt-services')}>
                {boltOns.services.map((service, i) => {
                    return (
                        <div key={`bolt-on-${i}`} className={classes('bolt-service')}>
                            <span className={classes('label')}>{service.label}</span>

                            <div className={classes('price-row')}>
                                {service.discounted && (
                                    <div className={classes('price-wrap', 'discounted')}>
                                        <span>{service.discounted}</span>
                                    </div>
                                )}
                                <div className={classes('price-wrap', { green: service.discounted })}>
                                    <span className={classes('price')}>{service.price}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
